/*
Portfolio & Processes
*/
.content.e-services{
	h1{
		&:before,
		&:after{
			width: 12%;

			@include media-breakpoint-up(md) {
				width: 29%;
			}
			@include media-breakpoint-up(lg) {
				width: 34%;
			}
			@include media-breakpoint-up(xl) {
				width: 38%;
			}
		}
	}

	.main_content{
		margin-bottom: 84px;

		@include media-breakpoint-up(xl) {
			margin-bottom: 118px;
		}
	}

	.road_wrapper{
		margin-bottom: 64px;

		@include media-breakpoint-up(md) {
			justify-content: center;
		    margin-left: -25px;
    		margin-right: -25px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 92px;
		}

		.road{
			margin-left: auto;
		    margin-right: auto;
	        display: block;
    		width: 50px;
			box-shadow: 0px 0px 62px 12px rgba(0, 0, 0, 0.1);
			background-image: url(../images/e-services/road_img.png);
			background-position: center top;
			background-size: 100% auto;
			background-repeat: repeat-y;
			height: 100%;
			
			@include media-breakpoint-up(md) {
				width: 90px;
			}
			@include media-breakpoint-up(lg) {
				width: 136px;
			}
			@include media-breakpoint-up(xl) {
				width: 152px;
			}
			
		}

		& > div{
			&:first-child{
				
				@include media-breakpoint-down(sm) {
					order: 4;
				    flex: 0 0 80%;
				    max-width: 80%;
				}
				@include media-breakpoint-between(lg, lg) {
					padding-right: 32px;
				}
				@include media-breakpoint-up(md) {
					padding-left: 0;
				}
			}
			&:last-child{

				@include media-breakpoint-down(sm) {
					order: 2;
				    flex: 0 0 80%;
				    max-width: 80%;
				}
				@include media-breakpoint-between(lg, lg) {
					padding-left: 33px;
				}
				@include media-breakpoint-up(md) {
					padding-right: 0;
				}
			}

			&.col-md-2{
				@include media-breakpoint-down(sm) {
					order: 1;
				    flex: 0 0 20%;
				    max-width: 20%;
				}
				@include media-breakpoint-up(xl) {
					max-width: 200px;
				}
			}

			&.mobile_road{
				order: 3;

				@include media-breakpoint-up(md) {
					display: none;
				}
			}
		}

		.item{
			h4{
				font-size: 24px;
				font-weight: 700;
				margin-bottom: 22px;
			}
			img.icon{
				position: relative;
				z-index: 1;
				margin-bottom: 16px;
				margin-top: 48px;

				@include media-breakpoint-down(sm) {
					max-width: 150px
				}
			}

			&.left{

				@include media-breakpoint-up(md) {
					text-align: right;
				}

				img.icon{
				    margin-right: -84px;

				    @include media-breakpoint-down(sm) {
						-webkit-transform: scaleX(-1);
  						transform: scaleX(-1);
  						margin-right: 0;
  						margin-left: -61px;
					}
				    @include media-breakpoint-up(lg) {
						margin-right: -128px;
					}
				}
			}
			&.right{

				img.icon{
					margin-left: -62px;
				    
				    @include media-breakpoint-up(md) {
						margin-left: -84px;
					}
				    @include media-breakpoint-up(lg) {
						margin-left: -126px;
					}
				}
			}

			@include media-breakpoint-up(md) {
				&.el2{
			   		margin-top: 290px;
				}
				&.el3{
				    margin-top: 55px;
				}
				&.el4{
				    margin-top: 65px;
				}
				&.el5{
				    margin-top: 260px;
				}
			}

			p.small{
				font-size: 12px;
			}
		}
	}

	.services{
		margin-bottom: 64px;
		text-align: center;

		@include media-breakpoint-up(md) {
			justify-content: center;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 102px;
		}

		h2{
			@include media-breakpoint-down(sm) {
				font-size: 28px;
			    margin-bottom: 24px;
			}
			@include media-breakpoint-between(md, md) {
				font-size: 18px;
			    margin-bottom: 28px;
			}
			@include media-breakpoint-between(lg, lg) {
				font-size: 24px;
			}

			&:before,
			&:after{
				@include media-breakpoint-down(sm) {
					top: 15px;
				}
				@include media-breakpoint-between(md, md) {
					top: 8px;
				}
				@include media-breakpoint-between(lg, lg) {
					top: 12px;
				}
			}
		}

		& > div{
			@include media-breakpoint-down(sm) {
				margin-bottom: 24px;
			}

			&:nth-child(1){
				@include media-breakpoint-up(md) {
					margin-bottom: 36px;
				}
				@include media-breakpoint-up(lg) {
					margin-bottom: 52px;
				}

				h2{
					&:before,
					&:after{
						width: 16%;
					}
				}
			}
			&:nth-child(2){
				@include media-breakpoint-up(md) {
					margin-bottom: 36px;
				}
				@include media-breakpoint-up(lg) {
					margin-bottom: 52px;
				}

				h2{
					&:before,
					&:after{
						width: 3%;
					}
				}
			}
			&:nth-child(3){
				@include media-breakpoint-up(md) {
					margin-bottom: 36px;
				}
				@include media-breakpoint-up(lg) {
					margin-bottom: 52px;
				}

				h2{
					&:before,
					&:after{
						width: 20%;
					}
				}
			}
			&:nth-child(4){
				h2{
					&:before,
					&:after{
						width: 28%;
					}
				}
			}
			&:nth-child(5){
				h2{
					&:before,
					&:after{
						width: 9%;
					}
				}
			}
		}

		.item{
			img{
				margin-bottom: 22px;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.btn{
		    padding: 18px 52px 14px 22px;

		    &:after{
	    	    right: 22px;
		    }
		}
	}

}